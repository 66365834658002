



























































import type { Showroom } from '@/inc/types'

import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import ShowroomInfo from '@/components/Showroom/Info.vue'
import ShowroomSection from '@/components/Showroom/Section.vue'
import GPill from '@/components/g/Pill.vue'

export default defineComponent({
  name: 'Subpage',
  setup(_props, ctx) {
    const { content, chrome } = useGetters(['content', 'chrome'])
    const status = ref<'nearest' | 'all'>('all')
    const showrooms = ref<Showroom[]>(content.value.showrooms.items)
    const popupContent = ref<Showroom | Record<string, unknown> | null>(null)
    const contactUrl = chrome?.value?.ctas?.headerRdv?.url

    const openPopup = (showroom: Showroom) => {
      popupContent.value = showroom

      console.log('[Showrooms] openPopup', popupContent.value)
    }

    const closePopup = () => {
      popupContent.value = null
    }

    const onShowroomsAutocomplete = (showroomsByDistance: Showroom[]) => {
      status.value = 'nearest'
      showrooms.value = showroomsByDistance
    }

    onMounted(() => {
      ctx.root.$ee.$on('card:click', openPopup)
    })

    return {
      content,
      status,
      showrooms,
      popupContent,
      contactUrl,
      openPopup,
      closePopup,
      onShowroomsAutocomplete,
    }
  },
  components: {
    ShowroomInfo,
    ShowroomSection,
    GPill,
  },
})
