



























import { defineComponent, PropType } from '@vue/composition-api'
import { Showroom } from '@/inc/types'
import { push, GtmLayer } from '@/inc/utils'

import GPill from '@/components/g/Pill.vue'

export default defineComponent({
  name: 'ShowroomCard',
  props: {
    content: {
      type: Object as PropType<Showroom>,
      required: true,
    },
  },
  components: { GPill },
  setup(_props, ctx) {
    const onLoad = (e: Event) => {
      ctx.emit('img:load', e.target)
    }

    const onClick = (showroom: Showroom) => {
      ctx.root.$ee.$emit('card:click', showroom)

      const layer: GtmLayer = {
        event: 'click_showroom',
        clickType: 'card',
        showroomName: showroom.value,
      }
      push(layer)
    }

    return { onLoad, onClick }
  },
})
