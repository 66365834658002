

























import { defineComponent } from '@vue/composition-api'
import { ShowroomSection } from '@/inc/types'

import ShowroomGrid from './Grid.vue'

export default defineComponent({
  name: 'showroom-section',
  props: {
    content: {
      type: Object as () => ShowroomSection,
      default: {} as ShowroomSection,
    },
  },

  components: {
    ShowroomGrid,
  },
})
