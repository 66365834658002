













































import { defineComponent } from '@vue/composition-api'
import { ContactCard } from '@/inc/types'

import ShowroomCard from './Card.vue'

export default defineComponent({
  name: 'showroom-grid',
  props: {
    content: {
      type: Array as () => ContactCard[],
      default: () => [],
    },
  },

  components: {
    ShowroomCard,
  },
})
